<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Access Token</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Access Token</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getLogsList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo Id</label>
                    <input type="text" class="form-control" placeholder="nhập id" v-model="id" />
                  </div>
                </form>
                <form class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getLogsList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
                <form class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Status</label>
                    <select class="form-select" v-model="status" @change="getLogsList">
                      <option value="">Tất cả</option>
                      <option value="1">Live</option>
                      <option value="0">Die</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="row mt-4">
                <form @submit.prevent="addToken" class="col-12">
                  <div class="form-group">
                    <label for="">Thêm token</label>
                    <textarea
                      type="text"
                      class="form-control"
                      rows="5"
                      placeholder="nhập token (mỗi dòng tương ứng 1 token)"
                      v-model="data"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <button class="btn bg-success" type="submit">Thêm</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách Token'"
            :columns="columns"
            :rows="manipulatedLogs"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center" slot-scope="props">
              <button
                class="btn btn-success font-10 py-1 btn-xs"
                @click="copyToken(props.row.token)"
              >
                Copy token
              </button>
              <error-modal-vue :error="props.row" />
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import accessTokenApi from "@/api/access-token";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery, catchError } from "@/helpers/index";
import ErrorModalVue from "./ErrorModal.vue";

export default {
  name: "AccessToken",
  components: {
    Datatable,
    LoadingVue,
    ErrorModalVue,
  },
  data() {
    return {
      username: null,
      id: null,
      limit: 50,
      logs: [],
      loading: false,
      status: "",
      data: "",
      columns: [
        { label: "id", field: "id" },
        { label: "Status", field: "status_message" },
        { label: "Token", field: "token_html", html: true },
        { label: "Error", field: "error_html", html: true },
        { label: "Ngày tạo", field: "created_at", timeago: true },
        { label: "Ngày cập nhật", field: "updated_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedLogs() {
      return this.logs.map((log) => {
        log.token_html = `<input class='error-cell' type='text' value='${log.token}'/>`;
        const error = log?.error || "";
        log.error_html = `<input class='error-cell' type='text' value='${
          error === "" ? "" : JSON.stringify(error)
        }' data-bs-toggle='modal' data-bs-target='#errorModal_${log.id}'/>`;
        log.status_name = this.statusName(log.status);
        if (log.status === 0 || log.status === "0") {
          log.color = true;
        }
        return log;
      });
    },
    tokenArray() {
      let array = this.data.match(/.+/g);
      return array;
    },
  },
  created() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      const params = {
        limit: this.limit,
        id: this.id,
        status: this.status,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await accessTokenApi.list(queries);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.logs = response.data;
        } else {
          this.$toastr.error(response.message);
        }
      } else {
        this.$toastr.error("Tải list token không thành công !");
      }
    },
    async addToken() {
      if (!this.data || this.data === "") {
        this.$toastr.error("Vui lòng nhập token !");
        return;
      }
      const postData = {
        data: this.tokenArray,
      };
      this.loading = true;
      const response = await accessTokenApi.add(postData);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.$toastr.success(response?.message || "Thêm thành công");
          this.getLogsList();
        } else {
          this.$toastr.error(catchError(response));
        }
      } else {
        this.$toastr.error("Tải list token không thành công !");
      }
    },
    copyToken(value) {
      this.$copyText(value)
        .then(() => {
          this.$toastr.success("Copy thành công");
        })
        .catch(() => {
          this.$toastr.success("Copy KHÔNG thành công");
        });
    },
    statusName(status) {
      switch (status) {
        case 0:
        case "0":
          return "Die";
        case 1:
        case "1":
          return "Live";
        default:
          return status;
      }
    },
  },
};
</script>

<style lang="scss">
.error-cell {
  border: 1px solid rgb(209, 209, 209);
  color: #495057;
  font-size: 12px;
  width: 300px;
  outline-color: rgb(125, 150, 204);
  border-radius: 6px;
  cursor: pointer;
}
</style>
